<template>
  <section class="main-navigation" :class="initialClass">
    <screen-loader-wrapper></screen-loader-wrapper>
    <top-header-menu-wrapper menu-class="main-nav-header" :noHelpOnline="true">
      <template v-slot:left>
        <button
          class="menu-avatar"
          v-if="displayCompanyInitials"
          @click="showProfile('company')"
          :class="{ 'cursor-default': !can('COMPANY_PROFILE', 'read') }"
        >
          <div
            :class="{ 'photo-added': companyPhoto }"
            class="initials"
            :style="{
              'background-image': `url(${companyPhoto})`
            }"
          >
            <span v-if="!companyPhoto">{{ displayCompanyInitials }}</span>
          </div>
        </button>
      </template>
      <ul class="user-info">
        <template v-if="hasData">
          <li class="user-name">
            {{ userData.data.first_name }} {{ userData.data.last_name }}
          </li>
          <li
            class="user-type"
            v-if="company && company.data && company.data.name"
          >
            {{ company.data.name }}
          </li>
        </template>
        <li class="user-type" v-if="hasData && !hasSubdomain">CLEBEX EMPIRE</li>
      </ul>
      <template v-slot:right>
        <button
          class="menu-avatar"
          v-if="displayUserInitials"
          @click="showProfile('user')"
          :class="{ 'cursor-default': !can('USER_PROFILE', 'read') }"
        >
          <div
            :class="{ 'photo-added': userPhoto }"
            class="initials round"
            :style="{
              'background-image': `url(${userPhoto})`
            }"
          >
            <span v-if="!userPhoto">{{ displayUserInitials }}</span>
          </div>
        </button>
      </template>
    </top-header-menu-wrapper>
    <nav
      class="menu-items icon-men1"
      v-if="translations && displayNavigation && displayNavigation.length"
    >
      <ul
        v-if="
          navigationInUse === 'countNavigation' ||
            navigationInUse === 'occupancyNavigation'
        "
        class="menu-section back-to-main"
      >
        <li class="menu-item">
          <router-link to="/home" class="block-list-item-button"
            ><icon icon="#cx-hea1-arrow-left" width="12" height="12" />
            <span>{{
              navigationInUse === "countNavigation"
                ? displayLabelName("menu", "count", "back-to-main")
                : displayLabelName("menu", "occupancy", "back-to-main")
            }}</span></router-link
          >
        </li>
      </ul>
      <ul
        class="menu-section"
        v-for="section in displayNavigation"
        :key="section.name"
      >
        <li class="menu-section-name">
          {{ displayLabelName("menu", section.label, section.label) }}
        </li>
        <template v-if="section.links && section.links.length">
          <li
            class="menu-item"
            v-for="navItem in section.label !== 'applications'
              ? section.links
              : section.links.sort((a, b) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )"
            :key="navItem.name"
          >
            <router-link
              class="block-list-item-button"
              :to="navItem.url"
              v-slot="{ isActive, route }"
              @click="setMenuStatus(false)"
            >
              <icon
                :class="{ active: parentIsActive(isActive, route) }"
                v-if="navItem.icon"
                :icon="`#${navItem.icon}`"
                style="width: 100%; height: 20px;"
              />
              <span>
                {{ displayLabelName("menu", section.label, navItem.label) }}
              </span>
              <icon
                v-if="
                  navigationInUse === 'occupancyNavigation' &&
                    section.label === 'occupancy-settings' &&
                    isOutdated
                "
                class="icon-app1"
                icon="#cx-app1-warning-red"
                width="18"
                height="18"
              />
              <icon
                class="app1"
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              />
            </router-link>
          </li>
        </template>
      </ul>
      <ul
        v-if="
          navigationInUse !== 'countNavigation' &&
            navigationInUse !== 'occupancyNavigation'
        "
        class="menu-section"
      >
        <li class="menu-item">
          <a href="#" @click.prevent="logout" class="block-list-item-button"
            ><icon icon="#cx-men1-profile" />
            <span>{{ displayLabelName("menu", "global", "logout") }}</span></a
          >
        </li>
      </ul>
    </nav>
    <nav class="menu-items icon-men1" v-else>
      <ul
        class="menu-section"
        v-if="translations && navigation && navigation.length"
      >
        <li class="menu-section-name">
          {{
            displayLabelName("menu", navigation[0].label, navigation[0].label)
          }}
        </li>
        <template v-if="navigation[0].links && navigation[0].links.length">
          <li
            class="menu-item"
            v-for="navItem in navigation[0].links.filter(item =>
              ['Profile', 'Preferences'].includes(item.name)
            )"
            :key="navItem.name"
          >
            <router-link
              class="block-list-item-button"
              :to="navItem.url"
              v-slot="{ isActive, route }"
              @click="setMenuStatus(false)"
            >
              <icon
                :class="{ active: parentIsActive(isActive, route) }"
                v-if="navItem.icon"
                :icon="`#${navItem.icon}`"
              />
              <span>
                {{
                  displayLabelName("menu", navigation[0].label, navItem.label)
                }}
              </span>
              <icon
                class="app1"
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              />
            </router-link>
          </li>
        </template>
      </ul>
      <ul class="menu-section">
        <li class="menu-item">
          <a href="#" @click.prevent="logout" class="block-list-item-button"
            ><icon icon="#cx-men1-profile" />
            <span>{{ displayLabelName("menu", "global", "logout") }}</span></a
          >
        </li>
      </ul>
    </nav>
    <footer class="screen-footer main-nav-footer">
      <figure class="main-nav-footer-logo">
        <img
          src="../../assets/images/main-nav-footer-logo.png"
          alt="Clebex logo"
        />
      </figure>
    </footer>
  </section>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters, mapState } from "vuex";
import { getUserId, getSubdomain } from "@/services/http-service";
import ScreenLoaderWrapper from "@/components/global/ScreenLoaderWrapper";
import navigation from "@/navigation.json";
import countNavigation from "@/services/count-menu.json";
import occupancyNavigation from "@/services/occupancy-menu.json";

export default {
  name: "MainNavigation",
  data() {
    return {
      navigation: navigation,
      countNavigation,
      occupancyNavigation,
      userId: getUserId()
    };
  },
  computed: {
    ...mapState("user", ["userData", "company", "userProfilePhoto"]),
    ...mapState("loader", ["loading"]),
    ...mapState("navigation", ["navigationInUse"]),
    ...mapGetters("user", ["userPhoto"]),
    ...mapGetters("companyProfile", ["companyPhoto"]),
    ...mapState("occupancy", ["isOutdated"]),
    initialClass() {
      const subdomain = getSubdomain();
      // alternate login theme class
      // theme-alternate

      if (subdomain) {
        return "theme-company";
      }
      return "theme-empire";
    },
    navigationMenu() {
      switch (this.navigationInUse) {
        case "countNavigation":
          return this.countNavigation;
        case "occupancyNavigation":
          return this.occupancyNavigation;
        default:
          return this.navigation;
      }
    },
    displayNavigation() {
      const { generalPermissions } = this;

      if (getSubdomain()) {
        if (
          generalPermissions &&
          generalPermissions.data &&
          generalPermissions.data.length
        ) {
          return this.navigationMenu.filter(item => {
            if (!item.type && !item.hidden && this.itemHasPermissions(item)) {
              item.links = item.links.filter(
                sub =>
                  !sub.hidden &&
                  this.itemHasPermissions(sub) &&
                  (!sub.type || sub.type === "company")
              );
              return item.links.length ? item : false;
            }
            if (
              item.type === "company" &&
              !item.hidden &&
              this.itemHasPermissions(item)
            ) {
              item.links = item.links.filter(
                sub => !sub.hidden && this.itemHasPermissions(sub)
              );
              return item.links.length ? item : false;
            }
          });
        }
      } else {
        return this.navigationMenu.filter(item => {
          if (!item.type && !item.hidden) {
            item.links = item.links.filter(
              sub => !sub.hidden && (!sub.type || sub.type === "master")
            );
            return item.links.length ? item : false;
          }
          if (item.type === "master" && !item.hidden) {
            item.links = item.links.filter(sub => !sub.hidden);
            return item.links.length ? item : false;
          }
        });
      }
      return null;
    },
    hasSubdomain() {
      return getSubdomain();
    },
    hasData() {
      return !!(this.userData && this.userData.data);
    },
    displayUserInitials() {
      if (this.hasData) {
        return (
          this.userData.data.first_name[0] + this.userData.data.last_name[0]
        );
      }
      return null;
    },
    displayCompanyInitials() {
      if (this.company && this.company.data && this.company.data.name) {
        return this.company.data.name[0];
      }
      return null;
    }
  },
  created() {
    if (this.hasSubdomain) {
      this.getCompanyUserData(this.userId);
      this.getUserProfilePhoto();
      this.getCompanyProfilePhoto();
      this.getCompanyProfile();
    } else {
      this.getMasterUserData(this.userId);
    }
    // console.log(this.$store._state.data);
  },
  methods: {
    ...mapActions("global", ["setMenuStatus"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions("user", [
      "getMasterUserData",
      "getCompanyUserData",
      "getUserProfilePhoto"
    ]),
    ...mapActions("companyProfile", [
      "getCompanyProfilePhoto",
      "getCompanyProfile"
    ]),
    parentIsActive(isActive, route) {
      return (
        isActive ||
        (this.$route && this.$route.name && this.$route.name === route.name)
      );
    },
    itemHasPermissions(item) {
      // if (process.env.NODE_ENV === "development") return true;
      if (item.permission) {
        const permission = this.generalPermissions.data.filter(
          per => per.permission === item.permission
        );
        if (permission.length) {
          if (permission[0].type === "CRUD") {
            return permission[0].is_active && permission[0]["read"];
          }
          return permission[0].is_active;
        }
        return false;
      }
      return true;
    },
    showProfile(permission) {
      this.setMenuStatus(false);
      if (this.can(`${permission.toUpperCase()}_PROFILE`, "read")) {
        this.$router.push({
          name: `r_${permission === "company" ? "company-" : ""}profile`
        });
      }
    }
  },
  components: {
    TopHeaderMenuWrapper,
    ScreenLoaderWrapper
  }
};
</script>
