<template>
  <main
    class="page"
    :class="{ 'menu-open': mainMenuStatus, 'menu-hidden': hideMainMenu }"
  >
    <section class="menu-wrapper">
      <main-navigation />
    </section>
    <section class="main-content">
      <div
        :class="
          `screens-wrapper${isFullWidthScreen ? ' full-width-screen' : ''}`
        "
      >
        <router-view />
      </div>
    </section>
    <button
      type="button"
      class="menu-close-overlay"
      @click="setMenuStatus(false)"
    >
      &nbsp;
    </button>
    <div class="notification" v-if="notification">
      <div class="notification-info">
        <span>{{
          displayLabelName("notifications.message.your-booking-was-declined")
        }}</span
        ><br /><br />
        <p v-if="declinedDeclarationSubject">
          {{ displayLabelName("notifications.message.subject") }}
          {{ declinedDeclarationSubject }}
        </p>
        <p v-if="declinedDeclarationResource">
          {{ displayLabelName("notifications.message.resource") }}
          {{ declinedDeclarationResource }}
        </p>
        <p v-if="declinedDeclarationPath">
          {{ displayLabelName("notifications.message.location") }}
          {{ declinedDeclarationPath }}
        </p>
        <p v-if="declinedDeclarationDate">
          {{ displayLabelName("notifications.message.date") }}
          {{ declinedDeclarationDate }}
        </p>
        <p v-if="declinedDeclarationTime">
          {{ displayLabelName("notifications.message.time") }}
          {{ declinedDeclarationTime }}
        </p>
      </div>

      <button class="notification-button" @click="closeNotification()">
        {{ displayLabelName("global.buttons.ok") }}
      </button>
    </div>
  </main>
</template>

<script>
import MainNavigation from "@/components/global/MainNavigation";
import { mapActions, mapState } from "vuex";
import { preferenceMenuModules } from "@/services/consts/modules/preference-menu-modules";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { getUserId } from "@/services/http-service";
import { wsEvents } from "@/services/constants";

export default {
  name: "LoggedInLayout",
  data() {
    return {
      notification: false,
      channel: null,
      declinedDeclarationSubject: null,
      declinedDeclarationResource: null,
      declinedDeclarationPath: null,
      declinedDeclarationDate: null,
      declinedDeclarationTime: null
    };
  },
  created() {
    this.channel = subscribe(
      "declaration",
      [getUserId()],
      wsEvents.declarationDecline.changed,
      this.wsCallback
    );
  },
  computed: {
    ...mapState("global", ["mainMenuStatus", "isFullWidthScreen", "showMenu"]),
    hideMainMenu() {
      return (
        !this.showMenu &&
        preferenceMenuModules.find(
          el => el.pathName === this.$route.matched[0].name
        )
      );
    }
  },
  methods: {
    ...mapActions("global", ["setMenuStatus"]),
    wsCallback(e) {
      this.declinedDeclarationSubject = e.subject;
      this.declinedDeclarationResource = e.resource;
      this.declinedDeclarationPath = e.path;
      this.declinedDeclarationDate = e.date;
      this.declinedDeclarationTime = e.time;
      this.notification = true;
      document.title = "(1) " + document.title;
    },
    closeNotification() {
      this.notification = false;
      document.title = document.title.replace("(1) ", "");
    }
  },
  components: {
    MainNavigation
  },
  beforeUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
      this.channel = null;
    }
  }
};
</script>
<style>
.notification {
  width: 300px;
  min-height: 200px;
  background-color: red;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 10000 !important;
  border-radius: 10px;
  box-shadow: 0 0.1875rem 0.375rem rgba(234, 232, 232, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification-info {
  flex: 1;
  padding: 10px;
  padding-top: 17px;
  width: 100%;
}

.notification-info span {
  margin-bottom: 10px;
}

.notification-info p {
  margin-bottom: 5px;
}

.notification-button {
  width: 100%;
  border-top: 1px solid white;
  height: 50px;
  color: white;
  font-size: 16px;
}
</style>
